
import { IDataNameFilter, InputTypes } from "@/models/common";
import { computed, defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import Calendar from "primevue/calendar";
import InputText from "primevue/inputtext";
import DateFormat from "@/utils/date-format";
export default defineComponent({
  name: "Date Name Filter",
  props: {
    filterState: {
      type: Object as PropType<IDataNameFilter>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    const state: IDataNameFilter = reactive({
      from: props.filterState.from,
      to: props.filterState.to,
      name: props.filterState.name,
    });

    const from = computed({
      get: () => {
        return state.from;
      },
      set: (val) => {
        state.from = DateFormat.StringDateWith(val, "-");
      },
    });
    const to = computed({
      get: () => {
        return state.to;
      },
      set: (val) => {
        state.to = DateFormat.StringDateWith(val, "-");
      },
    });

    return { state, InputTypes, t, from, to };
  },

  methods: {
    submit(e: Event) {
      e.preventDefault();
      this.$emit("onSearch", this.state);
    },
  },

  components: { Calendar, InputText },
});
